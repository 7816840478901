.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #b9b9b9;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-left-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link01 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link02 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link03 {
  text-decoration: none;
}
.home-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius24);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100VH;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
}
.home-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon02 {
  width: 24px;
  height: 24px;
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link05 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link06 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link07 {
  text-decoration: none;
}
.home-hero {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url("https://play.teleporthq.io/static/svg/default-img.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.home-content-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url('https://play.teleporthq.io/static/svg/logo-nobackground-200.png');
}
.home-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading {
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-text {
  color: var(--dl-color-gray-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-cta-btn1 {
  color: var(--dl-color-gray-white);
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tenunits);
  border-radius: 30px;
  padding-right: var(--dl-space-space-tenunits);
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-gray-black);
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text03 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-features-card {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon04 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text04 {
  color: var(--dl-color-gray-700);
  text-align: left;
}
.home-features-card1 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container1 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon07 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text05 {
  color: var(--dl-color-gray-700);
  text-align: left;
}
.home-features-card2 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container2 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon10 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text06 {
  color: var(--dl-color-gray-700);
  text-align: left;
}
.home-features-card3 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container3 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon13 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text07 {
  color: var(--dl-color-gray-700);
  text-align: left;
}
.home-features-card4 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container4 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon16 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading5 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text08 {
  color: var(--dl-color-gray-700);
  text-align: left;
}
.home-features-card5 {
  width: 30%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-icon-container5 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon19 {
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
}
.home-text-container5 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading6 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text09 {
  color: var(--dl-color-gray-700);
  text-align: left;
}
.home-services {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text10 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text11 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-cards-container1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-service-card {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-text12 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text13 {
  color: var(--dl-color-gray-700);
  text-align: left;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card1 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-text14 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text15 {
  color: var(--dl-color-gray-700);
  text-align: left;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-service-card2 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-text16 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text17 {
  color: var(--dl-color-gray-700);
  text-align: left;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-team {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container2 {
  width: 45%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text18 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text19 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-cards-container2 {
  width: 320px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-team-card {
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  flex-direction: column;
}
.home-avatar-container {
  width: 262px;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 266px;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: 32px;
  background-size: cover;
  background-image: url("/playground_assets/uscg_vet-300h.png");
}
.home-name {
  margin-bottom: var(--dl-space-space-unit);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-our-story {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-heading-container3 {
  width: 45%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
}
.home-text20 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text21 {
  color: var(--dl-color-gray-700);
  text-align: center;
}
.home-cards-container3 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-left-section {
  width: 50%;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  margin-right: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
}
.home-video-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--dl-color-gray-900);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-video {
  width: 100%;
  height: 350px;
  position: relative;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-play-container {
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  right: auto;
  width: 50px;
  bottom: auto;
  height: 50px;
  display: flex;
  position: absolute;
  align-items: center;
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.home-icon22 {
  fill: var(--dl-color-gray-500);
  width: 48px;
  height: 48px;
}
.home-content-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: flex-start;
}
.home-heading7 {
  text-align: left;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text22 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text23 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-info-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-stats-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-messages-container {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
}
.home-icon24 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-views-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon26 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-right-section {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: space-between;
}
.home-card {
  width: 48%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-image2 {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-content-container2 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-text26 {
  text-align: left;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-info-container1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-stats-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-messages-container1 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
}
.home-icon28 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-views-container1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon30 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-card1 {
  width: 48%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-image3 {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-content-container3 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-text29 {
  text-align: left;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-info-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-stats-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-messages-container2 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
}
.home-icon32 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-views-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon34 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-card2 {
  width: 48%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: space-between;
}
.home-image4 {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-content-container4 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-text32 {
  text-align: left;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-info-container3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-stats-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-messages-container3 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
}
.home-icon36 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-views-container3 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon38 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-card3 {
  width: 48%;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: space-between;
}
.home-image5 {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-content-container5 {
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-text35 {
  text-align: left;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-info-container4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-stats-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-messages-container4 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
}
.home-icon40 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-views-container4 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon42 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-get-in-touch {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-content-container6 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-footer-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.home-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-icon44 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon46 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon48 {
  width: 24px;
  height: 24px;
}
.home-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon50 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-content-container {
    flex-direction: column-reverse;
  }
  .home-hero-text {
    width: 80%;
  }
  .home-cards-container2 {
    justify-content: center;
  }
  .home-cards-container3 {
    flex-direction: column;
  }
  .home-left-section {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-right-section {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    border-radius: var(--dl-radius-radius-radius4);
    background-color: var(--dl-color-gray-black);
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-links-container {
    display: none;
  }
  .home-heading-container {
    width: 100%;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-features-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card3 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card4 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-features-card5 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-heading-container1 {
    width: 100%;
  }
  .home-cards-container1 {
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .home-service-card {
    width: 65%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-service-card1 {
    width: 65%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-service-card2 {
    width: 65%;
  }
  .home-heading-container2 {
    width: 100%;
  }
  .home-heading-container3 {
    width: 100%;
  }
  .home-cards-container3 {
    align-items: center;
    flex-direction: column;
  }
  .home-content-container6 {
    width: 100%;
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .home-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content-container {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-hero-text {
    width: 100%;
  }
  .home-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-features-card {
    width: 100%;
  }
  .home-features-card1 {
    width: 100%;
  }
  .home-features-card2 {
    width: 100%;
  }
  .home-features-card3 {
    width: 100%;
  }
  .home-features-card4 {
    width: 100%;
  }
  .home-features-card5 {
    width: 100%;
  }
  .home-services {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-service-card {
    width: 100%;
  }
  .home-service-card1 {
    width: 100%;
  }
  .home-service-card2 {
    width: 100%;
  }
  .home-team {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text18 {
    text-align: center;
  }
  .home-our-story {
    padding-top: var(--dl-space-space-fiveunits);
  }
  .home-text20 {
    text-align: center;
  }
  .home-video {
    height: 200px;
  }
  .home-card {
    width: 100%;
  }
  .home-card1 {
    width: 100%;
  }
  .home-card2 {
    width: 100%;
  }
  .home-card3 {
    width: 100%;
  }
  .home-content-container6 {
    padding-left: 0px;
  }
}
